var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.showDialog,
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "div",
            { staticClass: "wrapper", staticStyle: { overflow: "hidden" } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.data,
                    "label-width": "120px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { disabled: _vm.isCheck },
                        model: {
                          value: _vm.data.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "name", $$v)
                          },
                          expression: "data.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "报名时间", prop: "time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "-",
                          "value-format": "yyyy-MM-dd",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          disabled: _vm.isCheck,
                        },
                        model: {
                          value: _vm.time,
                          callback: function ($$v) {
                            _vm.time = $$v
                          },
                          expression: "time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动说明", prop: "desc" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { type: "textarea", disabled: _vm.isCheck },
                        model: {
                          value: _vm.data.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "desc", $$v)
                          },
                          expression: "data.desc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "限定业务员", prop: "limit_users" } },
                    [
                      _c("span", { staticStyle: { "margin-right": "15px" } }, [
                        _vm._v(_vm._s(_vm.rTableData.length) + "人"),
                      ]),
                      !_vm.isCheck
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSet },
                            },
                            [_vm._v(" 设置 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.data.detail, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "table" },
                  [
                    _c(
                      "el-card",
                      {
                        staticStyle: { position: "relative" },
                        attrs: { shadow: "hover" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tit",
                            staticStyle: {
                              width: "120px",
                              padding: "0 12px",
                              "line-height": "32px",
                              "text-align": "right",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: "请输入方案名称" },
                              model: {
                                value: item.detail_name,
                                callback: function ($$v) {
                                  _vm.$set(item, "detail_name", $$v)
                                },
                                expression: "item.detail_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            staticStyle: { "margin-top": "16px" },
                            attrs: {
                              stripe: "",
                              border: "",
                              data: item.detail_goods.type1,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_name",
                                label: "预存商品",
                                width: "210",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.isCheck
                                          ? _c(
                                              "div",
                                              [
                                                _c("goods-search", {
                                                  ref: "goodsSearch",
                                                  refInFor: true,
                                                  attrs: {
                                                    "f-key": row.goods_name,
                                                    "show-kouwei": false,
                                                  },
                                                  on: {
                                                    "add-rows": function (
                                                      $event
                                                    ) {
                                                      return _vm.addRows(
                                                        $event,
                                                        index,
                                                        1
                                                      )
                                                    },
                                                    "choose-goods-keyword":
                                                      function ($event) {
                                                        return _vm.chooseGoodsKeyword(
                                                          $event,
                                                          row
                                                        )
                                                      },
                                                    "select-goods-all":
                                                      function ($event) {
                                                        return _vm.selectGoods(
                                                          $event,
                                                          row,
                                                          1
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(row.goods_name)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_specs",
                                label: "规格",
                                width: "110",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.isCheck
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-input", {
                                                  model: {
                                                    value: row.goods_specs,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "goods_specs",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.goods_specs",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(row.goods_specs)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "unit_name",
                                label: "单位",
                                width: "90",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.isCheck
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "单位",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.unitSelected(
                                                          row,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: row.unit_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "unit_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.unit_id",
                                                    },
                                                  },
                                                  _vm._l(
                                                    row.unit,
                                                    function (unitItem) {
                                                      return _c("el-option", {
                                                        key: unitItem.id,
                                                        attrs: {
                                                          label:
                                                            unitItem.unit_name,
                                                          value: unitItem.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(row.unit_name)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_price",
                                label: "单价",
                                width: "90",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.isCheck
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/[^\\d.]/g,'') ",
                                                  },
                                                  model: {
                                                    value: row.goods_price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "goods_price",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.goods_price",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(row.goods_price)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_num",
                                label: "数量",
                                width: "90",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.isCheck
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/[^\\d.]/g,'') ",
                                                  },
                                                  model: {
                                                    value: row.goods_num,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "goods_num",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.goods_num",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(row.goods_num)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            !_vm.isCheck
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "操作",
                                    width: "110",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var $index = ref.$index
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.handleAddRow(
                                                      $index,
                                                      row,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 添加 ")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.handleDeleteRow(
                                                      $index,
                                                      row,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 删除 ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            staticStyle: { margin: "15px 0" },
                            attrs: {
                              stripe: "",
                              border: "",
                              data: item.detail_goods.type4,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_name",
                                label: "赠送商品",
                                width: "210",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.isCheck
                                          ? _c(
                                              "div",
                                              [
                                                _c("goods-search", {
                                                  ref: "goodsSearch",
                                                  refInFor: true,
                                                  attrs: {
                                                    "f-key": row.goods_name,
                                                  },
                                                  on: {
                                                    "add-rows": function (
                                                      $event
                                                    ) {
                                                      return _vm.addRows(
                                                        $event,
                                                        index,
                                                        4
                                                      )
                                                    },
                                                    "choose-goods-keyword":
                                                      function ($event) {
                                                        return _vm.chooseGoodsKeyword(
                                                          $event,
                                                          row
                                                        )
                                                      },
                                                    "select-goods-all":
                                                      function ($event) {
                                                        return _vm.selectGoods(
                                                          $event,
                                                          row,
                                                          4
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(row.goods_name)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_specs",
                                label: "规格",
                                width: "110",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(row.goods_specs)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "unit_name",
                                label: "单位",
                                width: "90",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.isCheck
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "单位",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.unitSelected(
                                                          row,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: row.unit_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "unit_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.unit_id",
                                                    },
                                                  },
                                                  _vm._l(
                                                    row.unit,
                                                    function (unitItem) {
                                                      return _c("el-option", {
                                                        key: unitItem.id,
                                                        attrs: {
                                                          label:
                                                            unitItem.unit_name,
                                                          value: unitItem.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(row.unit_name)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_num",
                                label: "数量",
                                width: "90",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.isCheck
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/[^\\d.]/g,'') ",
                                                  },
                                                  model: {
                                                    value: row.goods_num,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "goods_num",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.goods_num",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(row.goods_num)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            !_vm.isCheck
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "操作",
                                    width: "110",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var $index = ref.$index
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.handleAddRow1(
                                                      $index,
                                                      row,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 添加 ")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.handleDeleteRow1(
                                                      $index,
                                                      row,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 删除 ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "close",
                            on: {
                              click: function ($event) {
                                return _vm.handleDeletePlan(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              !_vm.isCheck
                ? _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-top": "15px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.handleAddPlan },
                    },
                    [_vm._v(" 添加方案 ")]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-form",
            { staticStyle: { "padding-left": "15px" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        "true-label": 1,
                        "false-label": 0,
                        disabled: _vm.isCheck,
                      },
                      model: {
                        value: _vm.data.price_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "price_status", $$v)
                        },
                        expression: "data.price_status",
                      },
                    },
                    [_vm._v(" 业务员签单时可修改价格 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        "true-label": 1,
                        "false-label": 0,
                        disabled: _vm.isCheck,
                      },
                      model: {
                        value: _vm.data.repeat_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "repeat_status", $$v)
                        },
                        expression: "data.repeat_status",
                      },
                    },
                    [_vm._v(" 活动可重复参加 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        "true-label": 1,
                        "false-label": 0,
                        disabled: _vm.isCheck,
                      },
                      model: {
                        value: _vm.data.exceed_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "exceed_status", $$v)
                        },
                        expression: "data.exceed_status",
                      },
                    },
                    [_vm._v(" 允许超收款下单 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1, disabled: _vm.isCheck },
                      model: {
                        value: _vm.data.check_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "check_status", $$v)
                        },
                        expression: "data.check_status",
                      },
                    },
                    [
                      _vm._v(
                        " 必须审核：业务员签定的预存货协议，必须后台审核后方可生效 "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0, disabled: _vm.isCheck },
                      model: {
                        value: _vm.data.check_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "check_status", $$v)
                        },
                        expression: "data.check_status",
                      },
                    },
                    [
                      _vm._v(
                        " 异常审核：勾选后，业务员修改协议内容，需后台审核后方可生效 "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赠品设置" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1, disabled: _vm.isCheck },
                      model: {
                        value: _vm.data.gift_goods_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "gift_goods_type", $$v)
                        },
                        expression: "data.gift_goods_type",
                      },
                    },
                    [_vm._v(" 无限制 ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2, disabled: _vm.isCheck },
                      model: {
                        value: _vm.data.gift_goods_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "gift_goods_type", $$v)
                        },
                        expression: "data.gift_goods_type",
                      },
                    },
                    [_vm._v(" 付款完成可下赠品 ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 3, disabled: _vm.isCheck },
                      model: {
                        value: _vm.data.gift_goods_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "gift_goods_type", $$v)
                        },
                        expression: "data.gift_goods_type",
                      },
                    },
                    [_vm._v(" 全部还货后可下赠品 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
      _c("set-component", {
        ref: "setComponent",
        attrs: { "is-create": true },
        on: { "set-userid": _vm.setUserId, "r-table-data": _vm.setRtableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }