<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="showDialog"
    top="5vh"
    width="1000px"
    destroy-on-close
  >
    <div v-loading="loading" class="box">
      <div class="wrapper" style="overflow: hidden">
        <el-form :model="data" label-width="120px" :rules="rules">
          <el-form-item label="活动名称" prop="name">
            <el-input
              v-model="data.name"
              :disabled="isCheck"
              style="width: 350px"
            ></el-input>
          </el-form-item>
          <el-form-item label="报名时间" prop="time">
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled="isCheck"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="活动说明" prop="desc">
            <el-input
              v-model="data.desc"
              type="textarea"
              style="width: 350px"
              :disabled="isCheck"
            ></el-input>
          </el-form-item>
          <el-form-item label="限定业务员" prop="limit_users">
            <span v-if="!isCheck" style="margin-right: 15px">
              {{ data.limit_count }}人
            </span>
            <span
              v-else
              style="
                margin-right: 15px;
                text-decoration: underline;
                cursor: pointer;
              "
              @click="handleCheckStaff"
            >
              {{ data.limit_count }}人
            </span>

            <el-button v-if="!isCheck" type="primary" @click="handleSet">
              设置
            </el-button>
          </el-form-item>
        </el-form>
        <div v-for="(item, index) in data.detail" :key="index" class="table">
          <el-card shadow="hover" style="position: relative">
            <div
              class="tit"
              style="
                width: 120px;
                padding: 0 12px;
                line-height: 32px;
                text-align: right;
              "
            >
              <!-- {{ item.detail_name }} -->
              <el-input
                v-model="item.detail_name"
                :disabled="isCheck"
                placeholder="请输入方案名称"
                style="width: 200px"
              ></el-input>
            </div>
            <el-table
              stripe
              border
              :data="item.detail_goods.type1"
              style="margin-top: 16px"
            >
              <el-table-column
                prop="goods_name"
                label="预存商品"
                width="210"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <goods-search
                      ref="goodsSearch"
                      :f-key="row.goods_name"
                      :show-kouwei="false"
                      @add-rows="addRows($event, index, 1)"
                      @choose-goods-keyword="chooseGoodsKeyword($event, row)"
                      @select-goods-all="selectGoods($event, row, 1)"
                    ></goods-search>
                  </div>
                  <span v-else>{{ row.goods_name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_specs"
                label="规格"
                width="110"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <el-input v-model="row.goods_specs"></el-input>
                  </div>
                  <span v-else>{{ row.goods_specs }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="unit_name"
                label="单位"
                width="90"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <el-select
                      v-model="row.unit_id"
                      placeholder="单位"
                      @change="unitSelected(row, $event)"
                    >
                      <el-option
                        v-for="unitItem in row.unit"
                        :key="unitItem.id"
                        :label="unitItem.unit_name"
                        :value="unitItem.id"
                      ></el-option>
                    </el-select>
                  </div>
                  <span v-else>{{ row.unit_name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_price"
                label="单价"
                width="90"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <el-input
                      v-model="row.goods_price"
                      oninput="value=value.replace(/[^\d.]/g,'') "
                    ></el-input>
                  </div>
                  <span v-else>{{ row.goods_price }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_num"
                label="数量"
                width="90"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <el-input
                      v-model="row.goods_num"
                      oninput="value=value.replace(/[^\d.]/g,'') "
                    ></el-input>
                  </div>
                  <span v-else>{{ row.goods_num }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="!isCheck"
                prop=""
                label="操作"
                width="110"
                align="center"
              >
                <template #default="{ $index, row }">
                  <el-button
                    type="text"
                    @click.native.prevent="handleAddRow($index, row, index)"
                  >
                    添加
                  </el-button>
                  <el-button
                    type="text"
                    @click.native.prevent="handleDeleteRow($index, row, index)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table
              stripe
              border
              :data="item.detail_goods.type4"
              style="margin: 15px 0"
            >
              <el-table-column
                prop="goods_name"
                label="赠送商品"
                width="210"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <goods-search
                      ref="goodsSearch"
                      :f-key="row.goods_name"
                      @add-rows="addRows($event, index, 4)"
                      @choose-goods-keyword="chooseGoodsKeyword($event, row)"
                      @select-goods-all="selectGoods($event, row, 4)"
                    ></goods-search>
                  </div>
                  <span v-else>{{ row.goods_name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_specs"
                label="规格"
                width="110"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <el-input v-model="row.goods_specs"></el-input>
                  </div>
                  <span v-else>{{ row.goods_specs }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="unit_name"
                label="单位"
                width="90"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <el-select
                      v-model="row.unit_id"
                      placeholder="单位"
                      @change="unitSelected(row, $event)"
                    >
                      <el-option
                        v-for="unitItem in row.unit"
                        :key="unitItem.id"
                        :label="unitItem.unit_name"
                        :value="unitItem.id"
                      ></el-option>
                    </el-select>
                  </div>
                  <span v-else>{{ row.unit_name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_num"
                label="数量"
                width="90"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="!isCheck">
                    <el-input
                      v-model="row.goods_num"
                      oninput="value=value.replace(/[^\d.]/g,'') "
                    ></el-input>
                  </div>
                  <span v-else>{{ row.goods_num }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="!isCheck"
                prop=""
                label="操作"
                width="110"
                align="center"
              >
                <template #default="{ $index, row }">
                  <el-button
                    type="text"
                    @click.native.prevent="handleAddRow1($index, row, index)"
                  >
                    添加
                  </el-button>
                  <el-button
                    type="text"
                    @click.native.prevent="handleDeleteRow1($index, row, index)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="!isCheck" class="close" @click="handleDeletePlan(index)">
              <i class="el-icon-close"></i>
            </div>
          </el-card>
        </div>
        <el-button
          v-if="!isCheck"
          type="primary"
          style="float: right; margin-top: 15px"
          @click="handleAddPlan"
        >
          添加方案
        </el-button>
      </div>
      <el-form style="padding-left: 15px">
        <el-form-item>
          <el-checkbox
            v-model="data.price_status"
            :true-label="1"
            :false-label="0"
            :disabled="isCheck"
          >
            业务员签单时可修改价格
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-checkbox
            v-model="data.repeat_status"
            :true-label="1"
            :false-label="0"
            :disabled="isCheck"
          >
            活动可重复参加
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-checkbox
            v-model="data.exceed_status"
            :true-label="1"
            :false-label="0"
            :disabled="isCheck"
          >
            允许超收款下单
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-radio v-model="data.check_status" :label="1" :disabled="isCheck">
            必须审核：业务员签定的预存货协议，必须后台审核后方可生效
          </el-radio>
        </el-form-item>
        <el-form-item>
          <el-radio v-model="data.check_status" :label="0" :disabled="isCheck">
            异常审核：勾选后，业务员修改协议内容，需后台审核后方可生效
          </el-radio>
        </el-form-item>
        <el-form-item label="赠品设置">
          <el-radio
            v-model="data.gift_goods_type"
            :label="1"
            :disabled="isCheck"
          >
            无限制
          </el-radio>
          <el-radio
            v-model="data.gift_goods_type"
            :label="2"
            :disabled="isCheck"
          >
            付款完成可下赠品
          </el-radio>
          <el-radio
            v-model="data.gift_goods_type"
            :label="3"
            :disabled="isCheck"
          >
            全部还货后可下赠品
          </el-radio>
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="!isCheck" type="primary" @click="handleSave">
        保存
      </el-button>
      <el-button @click="close">关闭</el-button>
    </div>
    <set-component
      ref="setComponent"
      chenlie
      is-create
      @r-table-data="setUserId"
    ></set-component>
    <staff-dialog
      v-if="isCheck"
      :id.sync="data.limit_users"
      ref="staffDialog"
    ></staff-dialog>
  </el-dialog>
</template>
<script>
  import { rowDetail, edit } from '@/api/createPreInventory'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  import StaffDialog from './staffDialog'
  export default {
    name: 'Edit',
    components: {
      GoodsSearch,
      SetComponent,
      StaffDialog,
    },

    data() {
      return {
        id: 0,
        title: '活动',
        loading: true,
        showDialog: false,
        time: [],
        isCheck: false,
        data: {},
        // data: {
        //   name: '活动修改18',
        //   desc: '活动修改17',
        //   join_start_at: '2019-01-01',
        //   join_end_at: '2023-10-10',
        //   price_status: 0, //下单时业务员可修改价格1是0否
        //   repeat_status: 1, //活动可重复参加1是0否
        //   gift_goods_type: 1, //赠品赠送条件1无限制2付完款3消费完
        //   check_status: 1, //审核1必须审核0异常审核
        //   status: 1,
        //   create_id: 1,
        //   create_name: '薛宝钗',
        //   create_at: '2020-11-14 15:59:37',
        //   limit_count: 1,
        //   limit_users: [],
        //   detail: [
        //     {
        //       detail_id: 35,
        //       detail_name: '17方案二1',
        //       detail_goods: {
        //         type1: [
        //           {
        //             type: 1,
        //             goods_id: 1,
        //             goods_name: '伊利枕奶240ML',
        //             goods_specs: '305ml',
        //             unit_id: 1,
        //             unit_name: '袋',
        //             goods_price: '3.50',
        //             goods_num: '10',
        //             total_amount: '35.00',
        //             unit: [
        //               {
        //                 id: 1,
        //                 unit_name: '瓶',
        //                 cost_price: '2.00',
        //                 base_price: '1.00',
        //                 sell_price: '600.00',
        //                 market_price: '999.12',
        //                 min_sell_price: '550.00',
        //                 max_sell_price: '700.00',
        //               },
        //               {
        //                 id: 2,
        //                 unit_name: '件',
        //                 cost_price: '10.00',
        //                 base_price: '2.00',
        //                 sell_price: '700.00',
        //                 market_price: '999.46',
        //                 min_sell_price: '650.00',
        //                 max_sell_price: '750.00',
        //               },
        //               {
        //                 id: 3,
        //                 unit_name: '箱',
        //                 cost_price: '40.00',
        //                 base_price: '3.00',
        //                 sell_price: '800.00',
        //                 market_price: '999.00',
        //                 min_sell_price: '770.00',
        //                 max_sell_price: '820.00',
        //               },
        //             ],
        //           },
        //           {
        //             type: 1,
        //             goods_id: 2,
        //             goods_name: '17枕奶240ML',
        //             goods_specs: '305ml',
        //             unit_id: 4,
        //             unit_name: '箱',
        //             goods_price: '28.00',
        //             goods_num: '10',
        //             total_amount: '280.00',
        //             unit: [
        //               {
        //                 id: 4,
        //                 unit_name: '盒',
        //                 cost_price: '0.00',
        //                 base_price: '0.00',
        //                 sell_price: '20.00',
        //                 market_price: '0.00',
        //                 min_sell_price: '1.00',
        //                 max_sell_price: '0.00',
        //               },
        //               {
        //                 id: 5,
        //                 unit_name: '瓶x',
        //                 cost_price: '0.00',
        //                 base_price: '0.00',
        //                 sell_price: '10.00',
        //                 market_price: '0.00',
        //                 min_sell_price: '2.00',
        //                 max_sell_price: '0.00',
        //               },
        //             ],
        //           },
        //         ],
        //         type4: [
        //           {
        //             type: 4,
        //             goods_id: 1,
        //             goods_name: '赠品伊利枕奶240ML',
        //             goods_specs: '305ml',
        //             unit_id: 1,
        //             unit_name: '袋',
        //             goods_price: '0.00',
        //             goods_num: '2',
        //             total_amount: '0.00',
        //             unit: [
        //               {
        //                 id: 1,
        //                 unit_name: '瓶',
        //                 cost_price: '2.00',
        //                 base_price: '1.00',
        //                 sell_price: '600.00',
        //                 market_price: '999.12',
        //                 min_sell_price: '550.00',
        //                 max_sell_price: '700.00',
        //               },
        //               {
        //                 id: 2,
        //                 unit_name: '件',
        //                 cost_price: '10.00',
        //                 base_price: '2.00',
        //                 sell_price: '700.00',
        //                 market_price: '999.46',
        //                 min_sell_price: '650.00',
        //                 max_sell_price: '750.00',
        //               },
        //               {
        //                 id: 3,
        //                 unit_name: '箱',
        //                 cost_price: '40.00',
        //                 base_price: '3.00',
        //                 sell_price: '800.00',
        //                 market_price: '999.00',
        //                 min_sell_price: '770.00',
        //                 max_sell_price: '820.00',
        //               },
        //             ],
        //           },
        //         ],
        //       },
        //     },
        //   ],
        // },

        rules: {
          name: [
            {
              required: true,
              message: '请输入活动名称',
              trigger: 'blur',
            },
          ],
        },
        form: {
          name: '', //活动名称
          join_start_at: '',
          join_end_at: '',
          desc: '', // 描述
          limit_users: '', //限定的业务员以,隔开
          sche_list: [], //	方案列表
          repeat_status: '', //活动可重复参加1是0否
          price_status: '', //下单时业务员可修改价格1是0否
          check_status: '', //审核1必须审核0异常审核
          gift_goods_type: '', //赠品赠送条件1无限制2付完款3消费完
          exceed_status: 0,
        },
      }
    },
    computed: {
      // limit_users_len() {
      //   if (this.data.limit_users) {
      //     return this.data.limit_users.length
      //   } else {
      //     return 0
      //   }
      // },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.resetForm()
        }
      },
      time(val) {
        if (val) {
          this.data.join_start_at = val[0]
          this.data.join_end_at = val[1]
        } else {
          this.data.join_start_at = ''
          this.data.join_end_at = ''
        }
      },
    },
    created() {
      this.addPlan = {
        detail_name: '',
        detail_goods: {
          type1: [
            {
              type: 1,
              goods_name: '',
              goods_id: '',
              goods_specs: '',
              unit_id: '',
              unit_name: '',
              goods_price: '',
              goods_num: '',
              total_amount: '',
              unit: [],
            },
          ],
          type4: [
            {
              type: 4,
              goods_id: '',
              goods_name: '',
              goods_specs: '',
              unit_id: '',
              unit_name: '',
              goods_price: '',
              goods_num: '',
              total_amount: '',
              unit: [],
            },
          ],
        },
      }
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await rowDetail({ info_id: this.id })
        if (code == 200) {
          console.log(data)
          this.data = data
          this.time = [this.data.join_start_at, this.data.join_end_at]
          if (this.data.limit_users.length == 0) {
            this.data.limit_count = 0
          } else {
            this.data.limit_users.forEach((item, index) => {
              if (item == '') {
                this.data.limit_users.splice(index, 1)
              }
            })
            this.data.limit_count = this.data.limit_users.length
          }
          // 判断表格是否为空
          this.data.detail.forEach((item, bindex) => {
            if (item.detail_goods.length == 0) {
              item.detail_goods = { type1: [], type4: [] }
              item.detail_goods.type1 = []
              item.detail_goods.type4 = []
            }
            try {
              if (item.detail_goods.type1.length == 0) {
                this.addRow(bindex, 1, 1)
              }
              if (item.detail_goods.type4.length == 0) {
                this.addRow(bindex, 1, 4)
              }
            } catch (e) {
              console.log(e)
              item.detail_goods.type1 = []

              item.detail_goods.type4 = []
              this.addRow(bindex, 1, 1)

              this.addRow(bindex, 1, 4)
            }
          })
          // if(this.data.detail)
        }
        this.loading = false
      },
      handleSet() {
        this.$refs.setComponent.getUserList(
          this.data.limit_users.length == 0 ? '' : this.data.limit_users
        )
        this.$refs.setComponent.showDialog = true
      },
      addRows(val, index, type) {
        console.log(val, index, type)
        let this_ = this
        if (val.length !== 0) {
          val.forEach((item) => {
            eval('this_.data.detail[index].detail_goods.type' + type).splice(
              eval('this_.data.detail[index].detail_goods.type' + type).length -
                1,
              0,
              Object.assign(item, {
                unit: item.arr_unit,

                type,
                goods_specs: item.specs,
              })
            )
            ;(item.unit_name = item.unit.filter(
              (list) => list.id == item.unit_id
            )[0].unit_name),
              this_.$set(item, 'goods_num', item.quantity)
          })
          this.$message.success('添加成功')
        }
      },
      handleSave() {
        let data = JSON.parse(JSON.stringify(this.data))
        data.detail.forEach((item) => {
          item.detail_goods = item.detail_goods.type1.concat(
            item.detail_goods.type4
          )
          // item.detail_goods.forEach((i, idx) => {
          //   // console.log('iii', i)
          //   if (i.goods_name) {
          //     d.push(i)
          //     // item.detail_goods.splice(idx, 1)
          //   }
          // })
        })
        console.log(data)
        let user = ''
        if (typeof data.limit_users == 'string') {
          user = data.limit_users
        } else {
          user = data.limit_users.join(',')
        }
        Object.assign(data, {
          detail: JSON.stringify(data.detail),
          limit_users: user,
        })
        edit(data).then((res) => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.showDialog = false
            this.$emit('refresh')
          }
        })
      },
      close() {
        this.showDialog = false
      },
      setUserId(val) {
        console.log(val)
        let id = []
        val.forEach((i) => {
          if (i.id) {
            id.push(i.id)
          } else {
            i.user_id && id.push(i.user_id)
          }
        })
        this.data.limit_users = id
        this.data.limit_count = id.length
      },
      chooseGoodsKeyword($event, row) {
        row.goods_name = $event
      },
      selectGoods($event, row, type) {
        console.log(row)
        console.log($event)
        row.goods_id = $event.goods_id
        row.goods_num = $event.quantity
        row.goods_price = $event.goods_price
        row.goods_specs = $event.specs
        row.type = type
        row.unit = $event.arr_unit
        row.unit_name = $event.unit_name
      },
      unitSelected(val, $event) {
        console.log(val, $event)
        val.unit_name = val.unit.filter(
          (item) => item.id == $event
        )[0].unit_name
        let units = val.unit
        let activeUnit = units.filter((item) => item.id == $event)[0]
        try {
          val.goods_price = activeUnit.sell_price
        } catch (e) {
          val.goods_price = ''
          console.log(e)
        }
      },
      handleAddPlan() {
        this.data.detail.push(JSON.parse(JSON.stringify(this.addPlan)))
      },
      handleAddRow(index, row, bindex) {
        // this.data.detail.detail_goods.type1.push(addObj)
        console.log(bindex)
        this.addRow(bindex, index, 1)
        // this.data.detail[bindex].detail_goods.type1.splice(index + 1, 0, {
        //   type: 1,
        //   goods_name: '',
        //   goods_specs: '',
        //   unit_id: '',
        //   unit_name: '',
        //   goods_price: '',
        //   goods_num: '',
        //   total_amount: '',
        //   unit: [],
        // })
      },
      handleDeleteRow(index, row, bindex) {
        console.log(bindex)
        this.deleteRow(bindex, index, 1)
      },

      handleAddRow1(index, row, bindex) {
        // this.data.detail.detail_goods.type4.push(addObj)
        console.log(bindex)
        this.addRow(bindex, index, 4)
      },
      handleDeleteRow1(index, row, bindex) {
        console.log(bindex)
        this.deleteRow(bindex, index, 4)
      },
      deleteRow(bindex, index, type) {
        if (
          eval('this.data.detail[bindex].detail_goods.type' + type).length <= 1
        ) {
          console.log(
            eval(
              'this.data.detail[bindex].detail_goods.type' +
                type +
                '[index].goods_name'
            ) !== ''
          )
          if (
            eval(
              'this.data.detail[bindex].detail_goods.type' +
                type +
                '[index].goods_name'
            ) !== ''
          ) {
            // this.$message.error('重写')
            eval('this.data.detail[bindex].detail_goods.type' + type).splice(
              index,
              1
            )
            this.addRow(bindex, index, type)
          } else {
            this.$message.error('不能删除最后一行')
          }
        } else {
          eval('this.data.detail[bindex].detail_goods.type' + type).splice(
            index,
            1
          )
        }
      },
      addRow(bindex, index, type) {
        // this.data.detail[bindex].detail_goods
        eval('this.data.detail[bindex].detail_goods.type' + type).splice(
          index + 1,
          0,
          {
            type: type,
            goods_name: '',
            goods_specs: '',
            unit_id: '',
            unit_name: '',
            goods_price: '',
            goods_num: '',
            total_amount: '',
            unit: [],
          }
        )
      },
      resetForm() {
        this.data = {}
      },
      // 删除方案
      handleDeletePlan(index) {
        console.log(index)
        this.data.detail.splice(index, 1)
      },
      // 查看限定业务员
      handleCheckStaff() {
        // console.log(this.$refs.StaffDialog.id)
        // this.$refs.staffDialog.id = this.data.limit_users
        this.$refs.staffDialog.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
</style>
