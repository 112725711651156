var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "限定业务员",
        visible: _vm.showDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper", staticStyle: { "min-height": "400px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { stripe: "", data: _vm.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "部门",
                  prop: "depart_name",
                  width: "auto",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "业务员姓名",
                  prop: "user_name",
                  width: "auto",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "电话",
                  prop: "mobile",
                  width: "auto",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }