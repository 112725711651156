<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="限定业务员"
    :visible.sync="showDialog"
  >
    <div class="wrapper" style="min-height: 400px">
      <el-table v-loading="loading" stripe :data="list">
        <el-table-column
          label="部门"
          prop="depart_name"
          width="auto"
          align="center"
        ></el-table-column>
        <el-table-column
          label="业务员姓名"
          prop="user_name"
          width="auto"
          align="center"
        ></el-table-column>
        <el-table-column
          label="电话"
          prop="mobile"
          width="auto"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getStaffList, findStaff } from '@/api/setPrice'
  export default {
    name: 'StaffDialog',
    components: {},
    props: {
      id: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        staffData: [],
        showDialog: false,
        loading: false,
        list: [
          // {
          //   depart_name: '部门123',
          //   user_name: '姓名123',
          //   mobile: '18888888888',
          // },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          // this.fetchData()
          console.log(this.id)
          this.findStaff()
        } else {
          // this.loading = true
        }
      },
    },
    created() {
      // getStaffList().then((res) => {
      //   this.staffData = res.data
      // })
    },
    mounted() {},
    methods: {
      close() {
        this.showDialog = false
      },
      findStaff() {
        this.loading = true
        findStaff({ limit_user: this.id.join(',') }).then((res) => {
          console.log(res.data)
          this.list = res.data
        })

        this.loading = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
