var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.queryForm, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "130px" },
                attrs: {
                  "popper-class": "select-idx",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "开始时间",
                },
                model: {
                  value: _vm.queryForm.start_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "start_time", $$v)
                  },
                  expression: "queryForm.start_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                staticStyle: { width: "130px" },
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  "popper-class": "select-idx",
                  placeholder: "结束时间",
                },
                model: {
                  value: _vm.queryForm.end_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "end_time", $$v)
                  },
                  expression: "queryForm.end_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入活动名称",
                  "popper-class": "select-idx",
                },
                model: {
                  value: _vm.queryForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "name", $$v)
                  },
                  expression: "queryForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "状态", "popper-class": "select-idx" },
                  model: {
                    value: _vm.queryForm.activity_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "activity_status", $$v)
                    },
                    expression: "queryForm.activity_status",
                  },
                },
                _vm._l(_vm.statusList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.handleQuery } }, [
                _vm._v("查询"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v("创建新活动")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            stripe: "",
            data: _vm.list,
            "show-summary": "",
            "summary-method": _vm.summaryFunction,
          },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "50" },
          }),
          _vm._l(_vm.finallyColunms, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  item.label === "排序"
                    ? {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              on: {
                                change: function ($event) {
                                  return _vm.blur_(row.id, row.sort)
                                },
                              },
                              model: {
                                value: row.sort,
                                callback: function ($$v) {
                                  _vm.$set(
                                    row,
                                    "sort",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "row.sort",
                              },
                            }),
                          ]
                        },
                      }
                    : null,
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "160px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleCheckRow($index, row)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleEditRow($index, row)
                          },
                        },
                      },
                      [_vm._v(" 修改 ")]
                    ),
                    row.status != 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleCopyRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 复制 ")]
                        )
                      : _vm._e(),
                    row.status_text == "正常"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleStopRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 停止 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.totalCount,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
      _c("create", { ref: "create", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }